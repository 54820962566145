import * as React from "react";

import "./App.scss";
import Nav from "./Nav";
import Prompts from "./Prompts";

export default function App() {
	return <div className="app">
		<Nav />
		<Prompts />
	</div>;
}