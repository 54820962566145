import * as React from "react";

const Nav = () => <section className="hero is-info is-bold">
	<div className="hero-body">
		<p className="title">
			Cornell Hillel
		</p>
		<p className="subtitle">
			Meal Registration
		</p>
	</div>
</section>;

export default Nav;