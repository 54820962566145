import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";

const appContainer = document.getElementById("app");
if (!appContainer) {
	throw new Error("No app container found.");
}
const root = createRoot(appContainer);
root.render(<App />);
