import * as React from "react";

const Button = (props: { buttonProps: { title: string, goToPhase?: number, goToLink?: string }, setPhase: (phase: number) => void }) => {
	if (props.buttonProps.goToPhase) {
		return <button className="button is-primary is-large" onClick={() => props.setPhase(props.buttonProps.goToPhase || 0)}>
			{props.buttonProps.title}
		</button>;
	}

	return <a className="button is-primary is-large" href={props.buttonProps.goToLink}>
		{props.buttonProps.title}
	</a>;
};

export default Button;