import * as React from "react";
import Button from "./Button";

import "./Prompts.scss";

const phases = [
	{
		question: "Are you a Cornell student?",
		text: "This includes current undergraduate students, as well as current graduate students and their spouses.",
		buttons: [
			{
				title: "Yes",
				goToPhase: 1,
			},
			{
				title: "No",
				goToLink: "https://kosher.scl.cornell.edu/no-auth",
			}
		]
	},
	{
		question: "Do you have an unlimited meal plan?",
		text: "It's free either way.",
		buttons: [
			{
				title: "Yes",
				goToLink: "https://kosher.scl.cornell.edu/auth",
			},
			{
				title: "No",
				goToPhase: 2
			}
		]
	},
	{
		question: "Now we would go to the google form.",
		text: "",
		buttons: []
	},
];

const Prompts = () => {
	const [phase, setPhase] = React.useState(0);

	return <div className="prompts">
		<p className="title">
			{phases[phase].question}
		</p>
		<p className="subtitle">
			{phases[phase].text}
		</p>
		<div className="buttons">
			{phases[phase].buttons.map((button, i) => <Button buttonProps={button} setPhase={setPhase} key={i} />)}
		</div>
	</div>;
};

export default Prompts;